<!-- 采购退货产品记录 -->
<template>
  <div class="pagePadding home">
    <Form inline class="formMarginBtm20 clearfix home1">
      <!-- 供应商名称 -->
      <FormItem>
        <span>供应商名称：</span>
        <Select placeholder="请选择" v-model="queryFrom.supplier_id" clearable filterable class="iviewIptWidth250" @on-change="supplierChange">
          <Option v-for="(item, index) in supplierSelect" :value="item.id" :key="index">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem>
        <span class="marginLeft40">产品编号：</span>
        <i-input placeholder="请输入" class="iviewIptWidth250" v-model="queryFrom.product_code"></i-input>
      </FormItem>
      <!-- 产品名称 -->
      <FormItem class="marginLeft40">
        <span>产品名称：</span>
        <Select placeholder="请选择" v-model="queryFrom.product_id" clearable filterable class="iviewIptWidth250" :disabled="!queryFrom.supplier_id" @on-change="productChange">
          <Option v-for="(item, index) in productSelect" :value="item.product_id" :key="index">{{ item.product_name }}</Option>
        </Select>
      </FormItem>
      <!-- 规格型号/SKU -->
      <FormItem class="marginLeft40">
        <span>规格/SKU：</span>
        <Select placeholder="请选择" v-model="queryFrom.product_code_id" clearable filterable class="iviewIptWidth250" :disabled="!queryFrom.product_id">
          <Option v-for="(item, index) in SKUSelect" :value="item.product_code_id" :key="index" :label="item.product_model_name + '-' + item.product_item_number">
            <span>{{ item.product_model_name }}</span>
            <span style="float: right; color: #ccc; margin-right: 20px;">{{ item.product_item_number }}</span>
          </Option>
        </Select>
      </FormItem>
      <!-- 订单状态 -->
      <FormItem class="marginLeft40">
        <span>订单状态：</span>
        <Select placeholder="请选择" v-model="queryFrom.purchase_return_state" clearable filterable class="iviewIptWidth250">
          <Option v-for="(item, index) in statusSelect" :value="item.purchase_return_state" :key="index">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <!-- 下单时间 -->
      <FormItem>
        <span>下单时间：</span>
        <DatePicker type="date" placeholder="请选择" class="iviewIptWidth250" clearable @on-change="changeTime($event, 1)"></DatePicker>
        <span style="padding: 0 10px;">至</span>
        <DatePicker type="date" placeholder="请选择" class="iviewIptWidth250" v-model="queryFrom.end_order_time" clearable :options="options" @on-change="changeTime($event, 2)"></DatePicker>
      </FormItem>
      <!-- 按钮 -->
      <FormItem class="po-create-number marginRight0" :label-width="20">
        <span class="pageBtn finger btnSure" @click="searchItem">查询</span>
        <span class="longTextBtn finger btnAdd marginLeft20" @click="togatherOutput">合并导出</span>
        <span class="pageBtn finger btnSure marginLeft20" @click="setupStatus = true">设置</span>
        <span class="pageBtn finger btnSure marginLeft20" @click="printing">打印</span>
      </FormItem>
    </Form>
    <!-- <Table :productList="listColumns" :productData="listData" :pages="pages" @change-page="changePage" totalText="条记录" :total="total">
      <div slot="summary" class="summary">
        总数量：<span class="color389">{{ total_number }}</span> 合计金额：<span class="color389">¥{{ total_amount }}</span
        >
      </div>
    </Table> -->
    <div class="clearfix tabDiv home2" ref="tabDiv">
      <Spin fix v-if="tabloading"></Spin>
      <Table :height="tableHeight" :productList="listColumns" :productData="listData" :option_page="option_page" highlight-row border :chaxunFlag="chaxunFlag" :rowClass="rowClassName"></Table>
    </div>
    <div class="summary" v-if="listData && listData.length > 0">
      总数量：<span class="color389 marginRight20">{{ total_number }}</span> 合计金额：<span class="color389">¥{{ total_amount }}</span>
    </div>
    <div class="pages" v-if="listData !== null && listData.length > 0 && total">
      <Page v-if="pages && total <= 60" :total="total" :current="pages.page" @on-change="changePage" class="fl" />
      <Page v-if="pages && total > 60" :total="total" :current="pages.page" @on-change="changePage" show-elevator class="fl" />
      <div class="fr totalFont" v-if="total">共计{{ total }}条记录</div>
    </div>
    <!-- <div style="position: relative; border: 1px solid #e8eaec; height: 500px; border-top: none !important;" class="clearfix noData" v-if="listData !== null && listData.length == 0">
        <img style="margin-top: 100px;" src="@/assets/images/tableNoData.png" />
      </div> -->
    <!-- 动态表头弹窗 -->
    <table-setup v-if="setupStatus" :pageList="titleList" :option_page="option_page" @cancleBtn="setupStatus = false" @sureBrn="sureSetup"></table-setup>
    <Modal v-model="status" title="请选择模版" cancel-text @on-cancel="status = false" width="568">
      <div class="clearfix paddingBot40px">
        <div class="item">
          <span class="itemTitle">模版类型：</span>
          <Select placeholder="请选择" v-model="template_type" filterable class="iviewIptWidth307" @on-change="typeChange">
            <Option v-for="(item, index) in TemplateList" :value="item.typeID" :key="index">{{ item.typeName }}</Option>
          </Select>
        </div>
        <div class="item">
          <span class="itemTitle">模版名称：</span>
          <Select placeholder="请选择" v-model="template_id" filterable class="iviewIptWidth307" :disabled="!template_type">
            <Option v-for="(item, index) in typeList" :value="item.template_id" :key="index">{{ item.template_name }}</Option>
          </Select>
        </div>
        <!-- <div class="item">
          <span class="itemTitle">每页行数：</span>
          <InputNumber  :min="0" :precision="0" v-model="paging" :active-change="false"  class="iviewIptWidth307"></InputNumber>
        </div> -->
      </div>
      <div slot="footer">
        <span class="pageBtn finger btnSure" @click="surePrint">确定</span>
      </div>
    </Modal>
  </div>
</template>

<script>
import Table from '@/components/table'
import TableSetup from '@/components/tableSetup'

export default {
  name: 'purchaseReturnedProductRecords',
  components: {
    Table,
    TableSetup,
  },
  data() {
    return {
      chaxunFlag: true,
      tabloading: false,
      TemplateList: [
        { typeName: '采购单', typeID: 1 },
        { typeName: '收货验收单', typeID: 2 },
        { typeName: '入库单', typeID: 3 },
        { typeName: '销售单', typeID: 4 },
        { typeName: '拣货单', typeID: 5 },
        { typeName: '出库单', typeID: 6 },
        { typeName: '采购记录', typeID: 7 },
        { typeName: '收货验收记录', typeID: 8 },
        { typeName: '入库记录', typeID: 9 },
        { typeName: '销售记录', typeID: 10 },
        { typeName: '拣货记录', typeID: 11 },
        { typeName: '出库记录', typeID: 12 },
      ],
      template_id: '',
      template_type: 7,
      typeList: [],
      status: false,
      titleList: [],
      option_page: '06',
      // 动态表头弹窗
      setupStatus: false,
      // 表格数据
      listData: [],
      // 表格头部标题
      listColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 75,
        },
        {
          title: '供应商',
          key: 'supplier_name',
          align: 'center',
          minWidth: 260,
        },
        {
          title: '产品名称',
          key: 'product_name',
          align: 'center',
          minWidth: 260,
        },
        {
          title: '注册证号/备案凭证号',
          key: 'licence_code',
          align: 'center',
          width: 230,
        },
        {
          title: '规格型号',
          key: 'model_name',
          align: 'center',
          width: 136,
        },
        {
          title: '货号/SKU',
          key: 'item_number',
          align: 'center',
          width: 136,
        },
        {
          title: '供货单价',
          // key: 'unit_price',
          render: (h, param) => {
            let num = param.row.unit_price
            if (!num) {
              num = 0
            }
            num = parseFloat((num + '').replace(/[^\d\.-]/g, '')).toFixed(2) + ''
            var valueArr = num.split('.')[0].split('').reverse()
            const valueFloat = num.split('.')[1]
            let valueString = ''
            for (let i = 0; i < valueArr.length; i++) {
              valueString += valueArr[i] + ((i + 1) % 3 == 0 && i + 1 != valueArr.length ? ',' : '')
            }
            const money = valueString.split('').reverse().join('') + '.' + valueFloat
            return h('span', '￥' + money)
          },
          align: 'center',
          width: 160,
        },
        {
          title: '数量',
          key: 'quantity',
          align: 'center',
          width: 160,
        },
        {
          title: '小计',
          // key: 'subtotal',
          render: (h, param) => {
            let num = param.row.subtotal
            num = parseFloat((num + '').replace(/[^\d\.-]/g, '')).toFixed(2) + ''
            var valueArr = num.split('.')[0].split('').reverse()
            const valueFloat = num.split('.')[1]
            let valueString = ''
            for (let i = 0; i < valueArr.length; i++) {
              valueString += valueArr[i] + ((i + 1) % 3 == 0 && i + 1 != valueArr.length ? ',' : '')
            }
            const money = valueString.split('').reverse().join('') + '.' + valueFloat
            return h('span', '￥' + money)
          },
          align: 'center',
          width: 160,
        },
        {
          title: '订单状态',
          key: 'purchase_return_state_str',
          align: 'center',
          width: 136,
        },
        {
          title: '订单时间',
          key: 'create_time',
          align: 'center',
          width: 136,
        },
        // {
        //   title: '状态描述',
        //   key: 'purchase_return_state_str',
        //   align: 'center',
        //   width: 136,
        // },
      ],
      // 表单拉下选项
      thirdList: [],
      // 查询参数
      tableHeight: 0,
      queryFrom: {
        product_code: '',
        supplier_id: undefined,
        product_id: undefined,
        product_code_id: undefined,
        start_order_time: '',
        end_order_time: '',
        purchase_return_state: '',
      },
      /* 搜索对象，分页时需要 */
      searchObj: {},
      pages: {
        rows: 10,
        page: 1,
      },
      total: 0,
      // 条目总数
      total_amount: 0 /* 合计金额 */,
      total_number: 0 /* 合计数量 */,
      /* 状态下拉 */
      statusSelect: [
        { purchase_return_state: '2', name: '待发货' },
        { purchase_return_state: '3', name: '已完成' },
        { purchase_return_state: '9', name: '已发货' },
      ],
      /* 供应商下拉 */
      supplierSelect: [],
      // 产品下拉
      productSelect: [],
      // 产品规格下拉
      SKUSelect: [],
      // 产品id
      product_id: 0,
      // 时间设置
      options: {},
      paging: null,
    }
  },
  methods: {
    printing() {
      this.status = true
      this.getTemplate()
    },
    // 获取最近的模板
    getTemplate() {
      // 获取模板名称
      this.$http.get(this.$api.templateInfo, { template_type: this.template_type }, false).then(res => {
        this.typeList = res.data
      })
      // 获取模板记录
      this.$http.get(this.$api.recentTemplate, { template_type: this.template_type }, false).then(res => {
        if (res.data) {
          this.template_id = res.data.template_id
        }
      })
    },
    surePrint() {
      let api_info = `${this.$api.purchaseReturnRecord.url}?`
      console.log(this.queryFrom)
      for (let objName in this.queryFrom) {
        console.log(objName)
        if (this.queryFrom[objName]) {
          api_info = api_info + `${objName}=${this.queryFrom[objName]}&`
        }
      }
      // api_info = this.queryFrom.specification_id_arr.length > 0 ? api_info + `specification_id_str=${this.queryFrom.specification_id_arr.join(',')}` : api_info
      if (!this.template_type) {
        this.$Message.warning('请选择模版类型')
        return
      }
      if (!this.template_id) {
        this.$Message.warning('请选择模版名称')
        return
      }
      console.log(api_info)
      this.$http.downFile(this.$api.templatePrint, { template_id: this.template_id, api_info: api_info, paging: this.paging }, true).then(res => {
        this.status = false
        this.$Message.success('导出成功')
        let blob = new Blob([res.data], { type: 'application/x-xlsx' })
        const aLink = document.createElement('a')
        aLink.href = URL.createObjectURL(blob)
        aLink.download = '采购退货产品记录.xlsx'
        aLink.click()
      })
    },
    // 发送请求获取 模版名称
    typeChange(e) {
      if (!e) return
      this.$http.get(this.$api.templateInfo, { template_type: e }, false).then(res => {
        this.typeList = res.data
      })
    },
    sureSetup(val) {
      this.queryOption()
    },
    // 获取表格表头
    queryOption() {
      this.tabloading = true
      this.$http
        .get(this.$api.systemOptionItem, { option_page: this.option_page })
        .then(res => {
          this.titleList = res.data.enable
          localStorage.setItem('adminTtitleList', JSON.stringify(res.data.list))
          for (let i = 0; i < this.titleList.length; i++) {
            if (this.titleList[i].show) {
              this.titleList[i].key = this.titleList[i].data_col
              this.titleList[i].width = this.titleList[i].column_width
              this.titleList[i].align = 'center'
              this.titleList[i].resizable = true
            }
          }
          this.listColumns = []
          this.listColumns.unshift({
            title: '序号',
            type: 'index',
            align: 'center',
            width: 60,
          })
          this.listColumns = [...this.listColumns, ...this.titleList]
          this.listColumns = JSON.parse(JSON.stringify(this.listColumns))
        })
        .then(() => {
          this.tabloading = false
          // this.getTableList(this.pages, {})
        })
    },
    // 反冲成功的行标红
    rowClassName(row, index) {
      if (row.recoil_status == 1) return 'redRow'
      return ''
    },
    /* 获取表格数据 */
    getTableList(pages, searchItem) {
      this.tabloading = true
      let obj = {}
      Object.assign(obj, pages, searchItem)
      this.$http.get(this.$api.purchaseReturnRecord, obj, false).then(res => {
        this.tabloading = false
        if (res.status) {
          this.listData = res.data
          this.total = res.total
          this.total_amount = res.total_amount
          this.total_number = res.total_number
          // 处理动态表头页面列表数据
          this.listData = this.$utils.dynamicHeaderList(this, this.listData)
          if (this.listData.length > 0) {
            this.$nextTick(() => {
              // 页面渲染完成后的回调
              this.tableHeight = this.$refs.tabDiv.offsetHeight + ''
            })
          } else {
            this.$nextTick(() => {
              // 页面渲染完成后的回调
              this.tableHeight = '0'
            })
          }
        }
      })
    },
    /* 分页方法 */
    changePage(e) {
      this.pages.page = e
      this.getTableList(this.pages, this.searchObj)
    },
    /* 搜索产品 */
    searchItem() {
      this.chaxunFlag = false
      let obj = {}
      obj.supplier_id = this.queryFrom.supplier_id
      obj.product_code = this.queryFrom.product_code
      obj.product_id = this.queryFrom.product_id
      obj.product_code_id = this.queryFrom.product_code_id
      obj.start_order_time = this.queryFrom.start_order_time ? this.$moment(this.queryFrom.start_order_time).format('YYYY-MM-DD') : ''
      obj.end_order_time = this.queryFrom.end_order_time ? this.$moment(this.queryFrom.end_order_time).format('YYYY-MM-DD') : ''
      obj.purchase_return_state = this.queryFrom.purchase_return_state
      this.searchObj = obj
      this.getTableList({ page: 1, rows: 10 }, obj)
    },
    /* 获取下拉列表 */
    getSelect() {
      // 获取供应商下拉
      this.$http.get(this.$api.getSupplierNameList).then(res => {
        if (res.status) {
          this.supplierSelect = res.data
        }
      })
    },
    // 产品名称改变
    productChange(e) {
      this.SKUSelect = []
      this.queryFrom.product_code_id = undefined
      if (!e) return
      // 获取规格下拉
      let obj = {
        product_id: e,
        supplier_id: this.queryFrom.supplier_id,
      }
      this.$http.get(this.$api.gspPruchaseReturnProductShow, obj).then(res => {
        if (res.status) {
          this.SKUSelect = res.data
        }
      })
    },
    // 供应商名称改变
    supplierChange(e) {
      this.productSelect = []
      this.queryFrom.product_id = undefined
      if (!e) return
      // 获取产品下拉
      this.$http.get(this.$api.gspPruchaseReturnProductShow, { supplier_id: e }).then(res => {
        this.productSelect = res.data
      })
    },
    // 时间改变
    changeTime(e, num) {
      if (num == 1) {
        let that = this
        that.queryFrom.start_order_time = e
        that.queryFrom.end_order_time = ''
        that.options = {
          disabledDate(date) {
            return date && date.valueOf() < new Date(that.queryFrom.start_order_time)
          },
        }
      } else {
        this.queryFrom.end_order_time = e
      }
    },
    // 合并导出
    togatherOutput() {
      this.$Message.warning('功能开发中...')
    },
  },
  created() {
    this.queryOption()
    this.getSelect()
  },
}
</script>

<style scoped lang="less">
// 表格
.tabDiv {
  .totalFont {
    font-size: 14px;
  }
  .pages {
    margin-top: 10px;
    overflow: hidden;
    line-height: 32px;
  }
}
.paddingBot40px {
  padding-bottom: 40px;
}
.item {
  display: flex;
  align-items: center;
  justify-items: center;
  margin-bottom: 20px;
  .itemTitle {
    width: 100px;
    color: #000;
    text-align: justify;
    height: 32px;
    line-height: 32px;
  }
  .itemTitle::after {
    content: '';
    display: inline-block;
    padding-left: 100%;
  }
}
.home {
  height: 100%;
  display: flex;
  flex-direction: column;
  .home2 {
    flex: 1;
    overflow-y: auto;
    height: 100%;
  }
}
</style>
